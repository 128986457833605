<template>
  <div class="fixed inset-0 overflow-hidden add-demo-account-vue">
    <div class="absolute inset-0 overflow-hidden">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form @submit.prevent="handleSubmit(addAccount)" @reset.prevent="reset">
          <section
            class="absolute inset-y-0 right-0 pl-0 sm:pl-10 max-w-full flex"
          >
            <div class="w-screen max-w-none sm:max-w-lg">
              <div
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div
                  class="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll"
                >
                  <header class="py-6 px-8 bg-gray-100">
                    <div>
                      <div class="flex justify-between">
                        <h3 class="text-lg leading-6 font-normal text-gray-900">
                          {{ $t("add-a-new-demo-account") }}
                        </h3>
                      </div>
                      <p
                        v-if="
                          $store.getters.get_demo_backend_accounts.length === 0
                        "
                        class="mt-1 max-w-2xl text-sm font-light text-gray-500"
                      >
                        {{ $t("sorry-you-cannot-have-any-more-demo-accounts") }}
                      </p>
                      <p
                        v-else
                        class="mt-1 max-w-2xl text-sm font-light text-gray-500"
                      >
                        {{
                          $t(
                            "choose-your-desired-trading-platform-and-currency-settings-for-your-demo-account"
                          )
                        }}
                      </p>
                      <div
                        class="h-7 flex items-center absolute top-5 right-5"
                        @click="$emit('close')"
                      >
                        <button
                          aria-label="Close panel"
                          class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                        >
                          <!-- Heroicon name: x -->
                          <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 18L18 6M6 6l12 12"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>
                  <div
                    v-if="$store.getters.get_demo_backend_accounts.length > 0"
                    class="relative flex-1 mt-0 sm:p-0 p-6"
                  >
                    <!-- Replace with your content -->
                    <div>
                      <div class="sm:px-0 pt-8">
                        <dl>
                          <div class="sm:px-8 sm:py-2">
                            <dt class="text-sm leading-5 font-normal mb-1">
                              {{ $t("select-account") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2 flex">
                                <button
                                  v-for="(
                                    account, index
                                  ) in get_demo_backend_accounts"
                                  id="account_1"
                                  :key="index"
                                  :class="
                                    selectedAccount === account
                                      ? 'border-blue-500'
                                      : ''
                                  "
                                  class="transition cursor-pointer duration-150 ease-in-out p-5 border rounded mr-3 w-full focus:outline-none"
                                  type="reset"
                                  @click="setAccount(account)"
                                >
                                  <img
                                    :src="
                                      selectedAccount === account
                                        ? require(`../../assets/${account.backend.type}-white.png`)
                                        : require(`../../assets/${account.backend.type}.png`)
                                    "
                                    alt="Meta Logo"
                                    class="m-auto"
                                  />
                                </button>
                              </div>
                            </dd>
                          </div>
                          <div class="sm:px-8 mt-5">
                            <dt class="text-sm leading-5 font-normal mb-1">
                              {{ $t("Currency") }}
                              <span class="text-sm text-red-600 h-4"> * </span>
                            </dt>
                            <dd
                              class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  v-if="selectedAccount.backend"
                                  v-slot="{ errors }"
                                  name="currency"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="currency"
                                    :options="
                                      get_demo_backend_accounts.find(
                                        (x) =>
                                          x.backend === selectedAccount.backend
                                      ).currencies
                                    "
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    label="symbol"
                                  ></v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div
                            v-if="
                              whitelabel !== 'TDSouthAfrica' &&
                              whitelabel !== 'EagleGlobalMarkets' &&
                              whitelabel !== 'OneTradingMarkets' &&
                              whitelabel !== 'TradeCoreUK' &&
                              whitelabel !== 'VCGMarkets' &&
                              whitelabel !== 'Dzengi'
                            "
                            class="sm:px-8 mt-5 mb-1"
                          >
                            <dt class="text-sm leading-5 font-normal">
                              {{ $t("account-type") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="account_type"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="accountType"
                                    :options="accountTypes"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    :reduce="
                                      (item) =>
                                        whitelabel === 'PolarisMarkets'
                                          ? item.value
                                          : item
                                    "
                                    :label="
                                      whitelabel === 'PolarisMarkets'
                                        ? 'label'
                                        : 'symbol'
                                    "
                                  ></v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                          <div
                            v-if="
                              whitelabel === 'ICMEU' ||
                              whitelabel === 'ICMTrader' ||
                              whitelabel === 'ICMCapital' ||
                              whitelabel === 'ICMMENA' ||
                              whitelabel === 'TradiNext'
                            "
                            class="sm:px-8 mt-5 mb-1"
                          >
                            <dt class="text-sm leading-5 font-normal">
                              {{ $t("Leverage") }}
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                            >
                              <div class="sm:col-span-2">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="leverage"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="leverage"
                                    :options="leverages"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    label="name"
                                  ></v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>

                          <div
                            v-if="
                              whitelabel !== 'TDSouthAfrica' &&
                              whitelabel !== 'EagleGlobalMarkets' &&
                              whitelabel !== 'OneTradingMarkets' &&
                              whitelabel !== 'TradeCoreUK' &&
                              whitelabel !== 'VCGMarkets' &&
                              whitelabel !== 'PolarisMarkets'
                            "
                            class="sm:px-8 mt-5"
                          >
                            <dt
                              class="text-sm leading-5 font-normal sm:col-span-2"
                            >
                              {{ $t("deposit-amount") }}
                              <span class="text-sm text-red-600 h-4"> * </span>
                            </dt>
                            <dd
                              class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3"
                            >
                              <div class="sm:col-span-3">
                                <ValidationProvider
                                  v-if="
                                    (whitelabel === 'ICMVC' ||
                                      whitelabel === 'ICMMU') &&
                                    accountType === 'ICM Micro'
                                  "
                                  v-slot="{ errors }"
                                  name="deposit_amount"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="depositAmount"
                                    :options="depositAmountsICMCent"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    label="name"
                                  >
                                  </v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                                <ValidationProvider
                                  v-else
                                  v-slot="{ errors }"
                                  name="deposit_amount"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="depositAmount"
                                    :options="depositAmounts"
                                    :placeholder="
                                      $t(
                                        'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                                      )
                                    "
                                    label="name"
                                  >
                                  </v-select>
                                  <span class="text-sm text-red-600 h-4">{{
                                    errors[0]
                                  }}</span>
                                </ValidationProvider>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                    <div class="px-8" v-if="errorMessage">
                      <alert-message :alertMessage="errorMessage" />
                    </div>
                  </div>
                </div>
                <div
                  v-if="$store.getters.get_demo_backend_accounts.length > 0"
                  class="flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"
                >
                  <span class="inline-flex rounded-md shadow-sm ml-3">
                    <button
                      class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                      type="reset"
                      @click="$emit('close')"
                    >
                      {{ $t("Cancel") }}
                    </button>
                  </span>
                  <span class="inline-flex rounded-md shadow-sm">
                    <Button
                      :disabled="isDisabled"
                      class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                      type="submit"
                    >
                      {{ $t("create-account") }}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SelectMenu from "@/components/SelectMenu";
import { accountTypes, depositAmounts, leverage } from "@/views/choices";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "AddDemoAccount",
  components: {
    AlertMessage,
    SelectMenu,
  },
  data() {
    return {
      errorMessage: null,
      selectedAccount: {},
      currency: "",
      leverage: "",
      accountType: "",
      accountTypes: accountTypes(),
      isDisabled: false,
      leverages: leverage(),
      depositAmount: "",
      whitelabel: process.env.VUE_APP_WHITELABEL,
      depositAmounts: depositAmounts(),
      depositAmountsICMCent: [
        {
          id: 100,
          name: "10",
        },
        {
          id: 101,
          name: "50",
        },
        {
          id: 102,
          name: "100",
        },
        {
          id: 103,
          name: "250",
        },
        {
          id: 104,
          name: "500",
        },
        {
          id: 105,
          name: "1000",
        },
        {
          id: 106,
          name: "5000",
        },
        {
          id: 107,
          name: "10000",
        },
        {
          id: 108,
          name: "25000",
        },
      ],
      demoLeverage: {
        value: 500,
        name: "1:500",
      },
    };
  },
  computed: {
    ...mapGetters(["get_demo_backend_accounts"]),
  },
  created() {
    this.$store.dispatch("get_backends").then(() => {
      if (this.get_demo_backend_accounts) {
        this.selectedAccount = this.get_demo_backend_accounts[0];
      }
    });
    // client requested that we remove 1:500 leverage only for live account, so we push it back for demo
    if (process.env.VUE_APP_WHITELABEL === "ICMTrader") {
      this.leverages.push(this.demoLeverage);
    }
  },
  watch: {
    // here we are watching if the user has changed the account type
    // because of ICMVC and ICMMU, deposit amout values change depending of the account type that is selected
    // so we need to reset taht value in case the user first selected the deposit amout and then the account type
    accountType: function (newValue, oldValue) {
      if (this.whitelabel === "ICMVC" || this.whitelabel === "ICMMU") {
        if (oldValue === "") {
          if (newValue === "ICM Micro") {
            this.depositAmount = "";
          }
        } else if (newValue === "ICM Micro" || oldValue === "ICM Micro") {
          this.depositAmount = "";
        }
      }
    },
  },
  methods: {
    addAccount() {
      this.isDisabled = true;
      let backendData;
      if (
        this.whitelabel === "TD365" ||
        this.whitelabel === "TDSouthAfrica" ||
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK"
      ) {
        backendData = this.selectedAccount.backend.id;
      } else {
        backendData = this.selectedAccount.backend.name;
      }
      const data = {
        account_type: this.accountType,
        backend: backendData,
        currency: this.currency,
        demo_deposit_amount: this.depositAmount.id,
        group: null,
      };
      if (this.whitelabel === "ICMVC") {
        // these are the grup data for dev
        if (
          window.location.hostname.includes("localhost") ||
          window.location.hostname.includes("dev.") ||
          window.location.hostname.includes("madetight.io")
        ) {
          if (this.selectedAccount.backend.type === "MT4") {
            data.group = "testmq_crmCD";
          }
          if (
            this.accountType === "ICM Zero" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            data.group = "demo\\ICMVCR1";
          }
          if (
            (this.accountType === "Direct" ||
              this.accountType === "ICM Micro") &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            data.group = "ICMVCdemo1";
          }
          if (
            (this.accountType === "Direct" ||
              this.accountType === "ICM Micro") &&
            this.selectedAccount.backend.type === "CTrader"
          ) {
            // data.group = 'ICMVCD1-Demo'
            data.group = null;
          }
          // these are the grup data for stage and live
        } else {
          if (
            this.accountType === "ICM Zero" &&
            this.selectedAccount.backend.type === "MT4"
          ) {
            data.group = "demo_ICMVCR1";
          }
          if (
            this.accountType === "ICM Zero" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            data.group = "demo\\ICMVCR1";
          }
          if (
            (this.accountType === "Direct" ||
              this.accountType === "ICM Micro") &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            data.group = "ICMVCdemo1";
          }
          if (
            (this.accountType === "Direct" ||
              this.accountType === "ICM Micro") &&
            this.selectedAccount.backend.type === "MT4"
          ) {
            data.group = "demo_ICMVCRS1C3";
          }
          if (
            (this.accountType === "Direct" ||
              this.accountType === "ICM Micro") &&
            this.selectedAccount.backend.type === "CTrader"
          ) {
            // data.group = 'ICMVCD1-Demo'
            data.group = null;
          }
        }
      }

      if (this.whitelabel === "GCCBrokers") {
        if (
          window.location.hostname.includes("localhost") ||
          window.location.hostname.includes("dev.") ||
          window.location.hostname.includes("staging.") ||
          window.location.hostname.includes("madetight.io")
        ) {
          data.group = "demo\\tc-test";
        } else {
          if (
            this.accountType === "Pro" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            if (this.currency === "USD") {
              data.group = "demo\\pro-usd";
            } else if (this.currency === "EUR") {
              data.group = "demo\\pro-eur";
            }
          }
          if (
            this.accountType === "Standard" &&
            this.selectedAccount.backend.type === "MT5"
          ) {
            if (this.currency === "USD") {
              data.group = "demo\\standard-usd";
            } else if (this.currency === "EUR") {
              data.group = "demo\\standard-eur";
            }
          }
        }
      }
      if (
        this.whitelabel === "FXViewEU" ||
        this.whitelabel === "FXViewGlobal"
      ) {
        data.group = null;
        // if (this.selectedAccount.backend.type === "MT4") {
        //   data.group = "demoSV-USD-REC";
        // }
        // if (this.selectedAccount.backend.type === "MT5") {
        //   data.group = "demo\\SV-USD-ECN";
        // }
        // if (this.selectedAccount.backend.type === "ActForex") {
        //   data.group = "demo\\act_forex";
        // }
      }
      if (this.whitelabel === "ICMCapital" || this.whitelabel === "TradiNext") {
        if (
          this.accountType === "ICM Zero" &&
          this.selectedAccount.backend.type === "MT4"
        ) {
          if (this.currency === "USD") {
            data.group = "PUSDr_ICMZDemo";
          } else if (this.currency === "EUR") {
            data.group = "PEURr_ICMZDemo";
          } else if (this.currency === "GBP") {
            data.group = "PGBPr_ICMZDemo";
          } else if (this.currency === "SGD") {
            data.group = "PSGDr_ICMZDemo";
          }
        }
        if (
          this.accountType === "ICM Zero" &&
          this.selectedAccount.backend.type === "MT5"
        ) {
          data.group = "demo\\ICMVCR1";
        }
      }
      if (this.whitelabel === "ICMMENA") {
        if (this.accountType === "ICM Zero") {
          if (this.selectedAccount.backend.type === "MT4") {
            if (this.currency === "USD") {
              data.group = "demoICMMER_AD";
            } else if (this.currency === "EUR") {
              data.group = "demoICMMERE_AD";
            } else if (this.currency === "GBP") {
              data.group = "demoICMMERGB_AD";
            }
          } else if (this.selectedAccount.backend.type === "MT5") {
            data.group = "demo\\ICMMER_AD";
          } else if (this.selectedAccount.backend.type === "CTrader") {
            data.group = "demo_ICMMER_AD";
          }
        } else if (this.accountType === "Direct") {
          if (this.selectedAccount.backend.type === "MT4") {
            if (this.currency === "USD") {
              data.group = "demoICMMED_AD";
            } else if (this.currency === "EUR") {
              data.group = "demoICMMEDE_AD";
            } else if (this.currency === "GBP") {
              data.group = "demoICMMEDGB_AD";
            }
          } else if (this.selectedAccount.backend.type === "MT5") {
            data.group = "demo\\ICMMED_AD";
          } else if (this.selectedAccount.backend.type === "CTrader") {
            data.group = "demo_ICMMED_AD";
          }
        }
      }
      if (this.whitelabel === "ICMMU") {
        // these are the grup data for dev env
        if (
          window.location.hostname.includes("localhost") ||
          window.location.hostname.includes("dev.") ||
          window.location.hostname.includes("madetight.io")
        ) {
          if (this.accountType === "ICM Zero") {
            if (this.selectedAccount.backend.type === "MT5") {
              if (this.currency === "USD") {
                data.group = "demo\\ICMMURS1";
              }
            }
          } else if (
            this.accountType === "Direct" ||
            this.accountType === "ICM Micro"
          ) {
            if (this.selectedAccount.backend.type === "MT4") {
              data.group = "testmq_crmCD";
            } else if (this.selectedAccount.backend.type === "CTrader") {
              data.group = "demo_ICMMUDS1";
            } else if (this.selectedAccount.backend.type === "MT5") {
              if (this.currency === "USD") {
                data.group = "demo\\ICMMUDS1";
              }
            }
          }
          // these are the grup data for stage and live
        } else {
          if (this.accountType === "ICM Zero") {
            if (this.selectedAccount.backend.type === "MT5") {
              if (this.currency === "USD") {
                data.group = "demo\\ICMMURS1";
              }
            }
          } else if (
            this.accountType === "Direct" ||
            this.accountType === "ICM Micro"
          ) {
            if (this.selectedAccount.backend.type === "MT4") {
              if (this.currency === "USD") {
                data.group = "demoICMMU-DEMO1";
              } else if (this.currency === "EUR") {
                data.group = "demoICMMUS-Eur";
              }
            } else if (this.selectedAccount.backend.type === "CTrader") {
              data.group = "demo_ICMMUDS1";
            } else if (this.selectedAccount.backend.type === "MT5") {
              if (this.currency === "USD") {
                data.group = "demo\\ICMMUDS1";
              }
            }
          }
        }
      }
      if (
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK"
      ) {
        delete data.account_type;
        // data.currency = 'DMD'
      }
      if (
        this.whitelabel === "ICMEU" ||
        this.whitelabel === "ICMTrader" ||
        this.whitelabel === "ICMCapital" ||
        this.whitelabel === "ICMMENA" ||
        this.whitelabel === "TradiNext"
      ) {
        data.leverage = this.leverage.value;
      }
      this.$store
        .dispatch("add_account", data)
        .then(() => {
          this.isDisabled = false;
          this.$emit("close");
          this.$store.dispatch("account_data");
          const t = this.$t.bind(this);
          this.$notify({
            group: "foo",
            text: t("new-demo-trading-account-is-created"),
          });
        })
        .catch((err) => {
          this.isDisabled = false;
          const checkWhitelabel =
            this.whitelabel === "ICMVC" ||
            this.whitelabel === "ICMMU" ||
            this.whitelabel === "ICMCapital" ||
            this.whitelabel === "ICMMENA";
          if (
            err.data?.error_reason === "Invalid account password(3006)" &&
            checkWhitelabel
          ) {
            this.errorMessage =
              "Important Security Update: New password guidelines for enhanced security have been put forth. In order to create a new MT5 Trading account, please change your ICM Access Password. Password must be 8 to 15 characters long, containing lowercase letters, uppercase letters, numbers and symbols ($, @, !, etc.)";
            return;
          }
          this.$notify({
            group: "foo",
            text: `${Object.entries(err.data)[0][1]}`,
            type: "warn",
          });
        });
    },
    setAccount(backend) {
      if (this.selectedAccount !== backend) {
        this.currency = "";
        this.accountType = "";
        this.depositAmount = "";
        this.leverage = "";
      }
      this.selectedAccount = backend;
    },
  },
};
</script>
